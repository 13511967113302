import React from "react"
import { Grid } from "@material-ui/core"
//COMPONENTS
import Article from "../components/article"
import Section from "../components/section"
import Card from "../components/card"
import BulletItem from "../components/bullet-item"
import Navigation from "../components/navigation"

//IMAGES
import ImageCover from "./assets/pexels-pixabay-434337.jpg"

//ICONS
import { IoIosArrowForward } from "react-icons/io"
import { HiOutlineMail } from "react-icons/Hi"
import { BsPeopleCircle } from "react-icons/Bs"
import { RiRunFill } from "react-icons/Ri"
import { SiMinutemailer } from "react-icons/Si"
import Footer from "../components/footer"

// this is how you use the icon directly: listItemIcon={<iconName/>}
// or you declar the name you want to use: const myIconName = <TiChevronRightOutline size="20px"/>
export default function PRT() {
  return (
    <>
      <Navigation active={0} />

      <Section
        className="playfair font-size-extralarge"
        color="turkis-blue1"
        bgImage={ImageCover}
        overlay="full"
        //overlayColor="rgba(250, 246, 246,.4)"
        overlayColor="rgba(116, 194, 200,.6)"
      >
        <Article
          layout="narrow"
          title="¡ Gracias por tu Confianza !"
          titleClassName="tenor white font-size-extralarge lineheight-large"
        ></Article>
      </Section>

      <Section color="white">
        <Article className="narrow raleway center font-size-medium  lineheight-verylarge">
          <div className="bold blue1 font-size-large">Importante:</div>
          Revisa tu correo electrónico para recibir los resultados de tu Test
          <br />
          <br />
        </Article>
        <Article className="narrow tenor bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          Bienvenida
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          A partir de hoy empieza tu transformación
          <br />
          <br />
        </Article>
        <Article
          layout="wide"
          titleClassName="bold blue1 font-size-verylarge lineheight-large"
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <BulletItem icon={<IoIosArrowForward />}>
                Recuerda que los resultados que obtengas de este test son
                únicamente para orientarte.
              </BulletItem>
              <BulletItem icon={<IoIosArrowForward />}>
                Es básico que sepas dónde te encuentras para que, a partir de
                allí, empieces a <strong> tomar acción con intención </strong>{" "}
                encaminada a{" "}
                <strong>
                  {" "}
                  reconocer tu valor único y potenciar tu confianza.
                </strong>
              </BulletItem>
              <BulletItem icon={<IoIosArrowForward />}>
                Los resultados los recibirás en tu email solo hay que seguir
                unos simples...
              </BulletItem>
            </Grid>
          </Grid>
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-verylarge">
          <div className="center bold blue1 font-size-large"> Pasos:</div>
        </Article>
      </Section>

      <Section color="white">
        <Article layout="wide">
          <Grid container spacing={3} justify="center">
            <Grid item md={3}>
              <Card
                icon={<HiOutlineMail size="36px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                title="Confirma tu email"
              >
                Revisa tu correo electrónico y tu bandeja de SPAM y da click en
                el enlace dentro del correo para verificar tu Email.{" "}
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                icon={<BsPeopleCircle size="32px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                title="Confirma que eres humana"
              >
                Acción requerida para confirmar que no eres un robot.
                <br />
                Solo da click en la casilla y luego en "Confirmar".
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card
                icon={<SiMinutemailer size="36px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Recibe tus resultados"
              >
                Te llegará un email con tus resultados del test.
              </Card>
            </Grid>
            {/* <Grid item md={3}>
              <Card
                icon={<FaCloudDownloadAlt size="36px" />}
                bgColor="white"
                color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Toma Acción"
              > 
              Completa la Guía para empezar a ver resultados.
              </Card>
            </Grid> */}
            <Grid item md={3}>
              <Card
                icon={<RiRunFill size="32px" color="#21447d" />}
                bgColor="white"
                //color="turkis-blue1"
                titleColor="turkis-blue1"
                shadow={false}
                className="center"
                titleClassName=""
                title="Toma acción con Intención"
              >
                También recibirás 2 emails con herramientas y consejos
                prácticos.
                <br />
                ¡Utilizalos para disfrutar del efecto que todo esto tiene en tu
                vida!
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      <Footer />
    </>
  )
}
